<template>
  <div id="app">
    <a-locale-provider :locale="locale">
      <router-view />
    </a-locale-provider>
  </div>
</template>

<script>
import Layout from "@/components/Layout/Layout.vue";

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  components: {
    Layout,
  },
};
</script>

<style lang="less">

@font-face {
		font-family: "DINALTERNATE";
		/* 定义字体名 */
		src: url("./assets/iconfont.ttf");
		/* 引入本体字体文件 */
	}
	.ant-cascader-menu-item[title="ANT_CASCADER_NOT_FOUND"] {
		display: none;
	}
	.ant-layout-content::-webkit-scrollbar {         /*滚动条整体样式*/
	  width:3px;
	  height:3px;
	}
	.ant-layout-content::-webkit-scrollbar-thumb {   /*滚动条里面小方块样式*/
	  border-radius:100px;
	  -webkit-box-shadow:inset 0 0 5px rgba(151, 151, 151, 0.2);
	  background:rgba(0,0,0,0.1);;
	} 
	.ant-layout-content::-webkit-scrollbar-track {   /*滚动条里面轨道样式*/
	  -webkit-box-shadow:inset 0 0 5px rgba(223, 223, 223, 0.2);
	  border-radius:100px;
	  background:rgba(0,0,0,0.1);
	}
	.roleee .chebox .ant-checkbox-group-item{
		margin-bottom: 6px;
	}
	@media screen and (max-width: 1280px) {
	    body {
	        width: 1500px !important;
	    }
	}
	.btn-span {
		color: #3473FF;
		margin-right: 10px;
		cursor: pointer;
	}
	.btn-span-delete {
		color: #FE0000;
	}
body {
  height: 100%;
}
#app {
  height: 100%;
}

.ant-menu>.ant-menu-item.ant-menu-item-selected {
	i.anticon {
		color: #3473FF !important;
	}
}

.ant-modal-title {
	text-align: center !important; 
}

// antd
.ant-layout {
  height: 100%;
}
.ant-breadcrumb {
  background-color: #fff;
  padding: 10px !important;
}
.ant-advanced-search-form {
  border-radius: 0px !important;
  background: #fff !important;
  border: none !important;
  padding: 16px !important;
  margin-top: 16px !important;
}

.search-list-box {
  .search-result-list {
    background: #fff !important;
  }
  .ant-form-item-control-wrapper {
    max-width: 70%;
  }
  .ant-form-item-label {
    min-width: 110px;
    text-align: right;
  }
  .ant-select-enabled {
    width: 100% !important;
  }
  .ant-calendar-picker {
    width: 100% !important;
  }
}

.ant-input {
  border-radius: 4px !important;
}

.search-result-list {
  padding: 22px !important;
  padding-bottom: 0px !important;
}

.table-header-box {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  .left {
    display: flex;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 700;
      color: #000;
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
}

.marginr20 {
  margin-left: 12px;
}

.model-footer {
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}
.fromdisflex .ant-form-item {
  display: flex;
}
.fromdisflex .ant-form-item-label {
  width: 130px;
  min-width: 130px;
}
.fromdisflex .ant-form-item-control-wrapper {
  width: 100%;
}
.ant-form .ant-form-item {
  margin-bottom: 14px;
}
.ant-table-tbody tr:nth-child(2n) {
  background: #F6F6F6;
}
.ant-menu.ant-menu-inline-collapsed .titb {
  display: none;
}

.ant-table-body table{
	border: 1px solid #eeeeee;
}


.ant-table-thead > tr > th {
	background: #F6F6F6 !important;
	    border-bottom: 1px solid #eeeeee;
}

.content-btns {
	display: flex;
	justify-content: center;
	width: 100%;
}

.ant-menu-submenu-selected {
	&>.ant-menu-submenu-title {
		background: linear-gradient(90deg, rgba(52,115,255,0.1000) 0%, rgba(52,115,255,0.0500) 100%);
		i {
			color: #3473FF;
			&:before {
				background: #3473FF !important;
			}
			&:after {
				background: #3473FF !important;
			}
		}
		span {
			color: #3473FF;
			
		}
	}
}
.ant-layout-sider{
	 transition: none !important;
}
.ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-item-selected {
	background-color: transparent !important; 
	color: #3473FF !important;
	   
}

.content-box-div {
	background-color: #fff;
}

.ant-modal-footer {
	    text-align: center !important;
	    border-top: 0px !important;
	    padding-bottom: 30px !important;
}

.ddetws .ant-upload-list{
	display: none;
}
.tabtopbb {
	.ant-table-thead {
		&>tr:nth-child(2) th{
			border-top: 1px solid #e8e8e8;
		}
	}
}
</style>
